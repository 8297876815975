<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      내가 준 리뷰 평점<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_tab_1">
      <div class="on">
        <router-link to="/mypage/after_evaluation">준 리뷰 평점</router-link>
      </div>
      <div>
        <router-link to="/mypage/before_evaluation">줄 리뷰 평점</router-link>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_5">
      <div class="sect_sort">
        <select name="" v-model="sort" @change="getReviewEvalutation(true)">
          <option value="latest">최신순</option>
          <option value="old">오래된순</option>
          <option value="score">평점순</option>
        </select>
      </div>
      <div class="sect_table">
        <table summary="">
          <colgroup>
            <col width="*" />
            <col width="60px" />
            <col width="80px" />
          </colgroup>
          <thead>
            <tr>
              <th>리뷰</th>
              <th>평점</th>
              <th>날짜</th>
            </tr>
          </thead>
          <tbody v-if="listArray.length > 0">
            <tr
              v-for="(list, idx) in listArray"
              :key="idx"
              @click="goPage(list.seq)"
            >
              <td>{{ list.title }}</td>
              <td>{{ list.score }}</td>
              <td>{{ list.reg_dt.substr(2, 8).replace(/-/g, ".") }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3" :style="{ textAlign: 'center' }">
                평점 준 리뷰가 없습니다.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!---->
    <div class="rn_more" v-if="totalPage > page">
      <router-link to="" @click.native="getReviewEvalutation()"
        >{{ limit }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      sort: "latest",
      page: 0,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.$store.dispatch("mypage/clearRammList");
    this.getReviewEvalutation(true);
  },
  computed: {
    ...mapState("mypage", [
      "result",
      "msg",
      "listArray",
      "totalPage",
      "limit",
      "checkAuth",
    ]),
  },
  methods: {
    async getReviewEvalutation(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("mypage/getReviewEvalutation", {
        sort: this.sort,
        page: this.page,
        reset,
        type: "after",
      });
      this.page++;
    },
    goPage(seq) {
      const item = this.listArray.find((list) => list.seq === seq);

      if (item.del_yn === "Y") {
        this.$toast.default("삭제된 리뷰 입니다.");
        return false;
      }
      if (parseInt(item.main_menu_seq) === 1) {
        this.$router.push(
          `/review/goods_main/${item.category_code}?option1=${item.option_category1}&option2=${item.option_category2}&option3=${item.option_category3}&page=0&seq=${item.review_seq}`
        );
      } else {
        this.$router.push(
          `/review/news_main/${item.option_category1}?category=${item.option_category2}&seq=${item.review_seq}`
        );
      }
    },
  },
};
</script>

<style></style>
